<template>
  <CRow>
    <CCol col="12" xl="12">

      <CButton
        variant="outline" 
        type="submit" 
        color="primary" 
        class='list-new-btn'
        @click="$router.push({path: '/manager/users/create'})"
        v-if="$store.getters.isLocationAdmin"
        >
        <CIcon name="cil-check-circle" />New user
      </CButton> 

      <transition name="slide">
        <CCard>
          <CCardHeader>
            Users
          </CCardHeader>
          <CCardBody>
            <CDataTable
                    hover
                    sorter
                    striped
                    table-filter
                    :items="items"
                    :fields="fields"
                    :items-per-page="perPage"
                    :pagination="$options.paginationProps"
                    index-column
            >
              <template #location="{item, index}">
                <td class="py-2">
                  <p v-if="item.location">{{ item.location.name }}</p>
                </td>
              </template>
              <template #actions="{item, index}">
                <td class="py-2">
                  <CButton
                          color="primary"
                          variant="outline"
                          square
                          size="sm"
                          @click="detailClicked(item, index)"
                  >
                    Details
                  </CButton>
                </td>
              </template>

            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>

import UserAPI from '/app/src/api/user.js'

export default {
  name: 'Users',
  data: () => {
    return {
      userAPI: new UserAPI(),
      items: [],
      fields: [
        { key: 'first_name', label: 'First Name' },
        { key: 'last_name', label: 'Last Name' },
        { key: 'email' },
        { key: 'type' },
        { key: 'location' },
        { key: 'actions' }
      ],
      perPage: 20,
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  created: function() {
    this.getUsers();
  },
  methods: {
    getUsers: function() {
      this.$store.dispatch('loading')
      let self = this
      self.userAPI.all()
      .then((users) => {
        this.$store.dispatch('stopLoading')
        console.log(users)
        self.items = users
      })
      .catch((error) => {
        console.log(error)
        this.$store.dispatch('stopLoading')
        this.$alert.show({type: 'danger', 'message': error})
      })
    },
    detailClicked (item, index) {
      const itemLink = `/manager/users/${item.id.toString()}`
      this.$router.push({path: itemLink})
    }
  }
}
</script>
